import { Home } from "./Home";
import { useState } from "react";
import { AppLayout } from "@cloudscape-design/components";
import { CodeView } from "@cloudscape-design/code-view";
import Avatar from "@cloudscape-design/chat-components/avatar";
import ChatBubble from "@cloudscape-design/chat-components/chat-bubble";
import LoadingBar from "@cloudscape-design/chat-components/loading-bar";
import LiveRegion from "@cloudscape-design/components/live-region";
import { ReactTyped } from "react-typed";
import {
  ContentLayout,
  Badge,
  Header,
  Textarea,
  ButtonGroup,
  PromptInput,
  StatusIndicator,
  Link,
  ColumnLayout,
  TextContent,
  Drawer,
  ExpandableSection,
  KeyValuePairs,
  Grid,
  Input,
  BreadcrumbGroup,
  Box,
  SpaceBetween,
  Icon,
  HelpPanel,
  Container,
  Button,
} from "@cloudscape-design/components";
function HomePage() {
  const [toolsOpen, setToolsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [currentBubble, setCurrentBubble] = useState(0);
  const [openIntial, setOpenInitial] = useState(false);

  const messages = [
    "Can you describe a specific project you've worked on that highlights your expertise in generative AI and cloud-native applications ?",
    "One key generative AI project is the Amazon Kendra and huggingface e5-large embeddings model Chatbot with Retrieval Augmented Generation (RAG). It integrates generative AI with enterprise data using Amazon Kendra and AWS services to create scalable, context-aware question-and-answer bots. This solution has been applied in industries like healthcare, enabling localized content generation and enhancing compliance workflows.",
    "How do your certifications and educational background contribute to your ability to build intelligent and scalable cloud solutions ?",
    "My AWS Machine Learning and Data Analytics certifications and Computer Science degrees from Syracuse University provide the expertise to design scalable cloud-native applications. This foundation enabled me to develop solutions like the Pharma Ad Generator, combining generative AI and YOLOv3 image recognition to create tailored, high-quality content.",
  ];

  const handleMessageComplete = () => {
    if (currentBubble < messages.length - 1) {
      setCurrentBubble(currentBubble + 1);
    }
  };

  return (
    <AppLayout
      content={<Home />}
      toolsHide={false}
      navigationHide={true}
      onToolsChange={({ detail }) => {
        setToolsOpen(detail.open);
        setOpenInitial(true);
      }}
      contentType="default"
      tools={
        <HelpPanel
          header={
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <h2>Studio Agent Chatbot</h2>
              <Badge color="red">Offline</Badge>
            </div>
          }
        >
          {openIntial && (
            <div className="help-panel-content">
              {messages.map(
                (message, index) =>
                  index <= currentBubble && (
                    <ChatBubble
                      key={index}
                      ariaLabel={`Generative AI assistant at ${new Date().toLocaleTimeString()}`}
                      type={index % 2 === 0 ? "outgoing" : "incoming"}
                      actions={
                        <ButtonGroup
                          ariaLabel="Chat bubble actions"
                          variant="icon"
                          items={[
                            {
                              type: "icon-button",
                              id: "copy",
                              iconName: "copy",
                              text: "Copy",
                              popoverFeedback: (
                                <StatusIndicator type="success">
                                  Message copied
                                </StatusIndicator>
                              ),
                            },
                          ]}
                        />
                      }
                      avatar={
                        index % 2 === 0 ? (
                          <Avatar
                            ariaLabel="Avatar of John Doe"
                            tooltipText="John Doe"
                          />
                        ) : (
                          <Avatar
                            color="gen-ai"
                            iconName="gen-ai"
                            ariaLabel="Generative AI assistant"
                            tooltipText="Generative AI assistant"
                          />
                        )
                      }
                    >
                      {index === currentBubble && (
                        <ReactTyped
                          strings={[message]}
                          typeSpeed={10}
                          loop={false}
                          backSpeed={1}
                          cursorChar="-"
                          showCursor={false}
                          onComplete={handleMessageComplete}
                        />
                      )}

                      {index < currentBubble && <span>{message}</span>}
                    </ChatBubble>
                  )
              )}

              <LiveRegion>
                <Box
                  margin={{ top: "l", bottom: "m", left: "xxxs" }}
                  color="text-body-secondary"
                >
                  Generating a response
                </Box>

                <Box margin={{ left: "xxxs" }}>
                  <LoadingBar variant="gen-ai" />
                </Box>
              </LiveRegion>
            </div>
          )}

          <div
            className="tools"
            style={{
              backgroundColor: "#000716",
            }}
          >
            <Box className={"chat-input-container"}>
              <PromptInput
                onChange={({ detail }) => setMessage(detail.value)}
                value={message}
                readOnly
                actionButtonAriaLabel="Send message"
                actionButtonIconName="send"
                ariaLabel="Prompt input with action button"
                disableSecondaryActionsPaddings
                placeholder="Ask me a question"
                secondaryActions={
                  <Box padding={{ left: "xxs", top: "xs" }}>
                    <ButtonGroup
                      ariaLabel="Chat actions"
                      items={[
                        {
                          type: "icon-button",
                          id: "copy",
                          iconName: "upload",
                          text: "Upload files",
                        },
                        {
                          type: "icon-button",
                          id: "expand",
                          iconName: "expand",
                          text: "Go full page",
                        },
                      ]}
                      variant="icon"
                    />
                  </Box>
                }
              />

              <div style={{ paddingTop: "2%", paddingBottom: "2%" }}>
                <Box
                  display="inline"
                  fontSize="body-s"
                  fontWeight="normal"
                  textAlign="left"
                  variant="p"
                >
                  Studio Chat uses generative AI to enhance user interaction.
                  You may need to verify responses. If you have any questions
                  please contact jtanruan@gmail.com
                </Box>
              </div>
            </Box>
          </div>
        </HelpPanel>
      }
    />
  );
}

export default HomePage;
