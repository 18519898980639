import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { TopBarNavigation } from "./components/TopBarNavigation";
import Home from "./pages/Index";
import Footer from "./components/footer/footer";

function App() {
  return (
    <Router>
      <TopBarNavigation />
      <Switch>
        <Route path="/" exact={true} component={Home} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
