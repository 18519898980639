import TopNav from "@cloudscape-design/components/top-navigation";
import { Mode, applyMode } from "@cloudscape-design/global-styles";
import React, { useEffect, useState, useMemo } from "react";

export function TopBarNavigation() {
  const i18nStrings = {};

  applyMode(Mode.Dark);

  return (
    <div
      style={{
        paddingTop: "0.2%",
        paddingBottom: "0.2%",
        backgroundColor: "#000d28",
      }}
    >
      <TopNav
        i18nStrings={i18nStrings}
        identity={{
          href: "/",
          title: "Studio",
          logo: {
            src: "/logo.svg",
            alt: "logo",
          },
        }}
        utilities={[
          {
            type: "button",
            text: "   LinkedIn",
            title: "   LinkedIn",
            iconAlign: "left",
            iconUrl: "/linkedin.svg",
            href: "https://www.linkedin.com/in/ztanruan/",
            externalIconAriaLabel: " (opens in a new tab)",
          },
          {
            type: "button",
            text: "   GitHub",
            title: "   GitHub",
            iconAlign: "left",
            iconUrl: "/github-sign.svg",
            href: "https://github.com/ztanruan",
            externalIconAriaLabel: " (opens in a new tab)",
          },
          {
            type: "button",
            text: "   Contact",
            title: "   Contact",
            iconAlign: "left",
            iconUrl: "/envelope.svg",
            href: "mailto:jtanruan@gmail.com",
            externalIconAriaLabel: " (opens in a new tab)",
          },
          {
            type: "button",
            variant: "primary",
            text: "   Resume",
            title: "   Resume",
            iconUrl: "/cv.svg",
            href: "/resume.pdf",
          },
        ]}
      />
    </div>
  );
}
