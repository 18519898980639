import React from "react";

import "./index.css";

export default function Footer() {
  const year = new Date().toISOString().split("-")[0];

  return (
    <div className="container">
      <img class="footer-image" src="/logo.svg" height="30x" alt="jtanruan" />
      <a target="_blank" href="/" className="flex flex-column" rel="noreferrer">
        <div>STUDIO</div>
        <div className="font-size-10">Portfolio</div>
      </a>
      <span className="flex" />
      <div className="font-size-10 copyright">
        Copyright © {year} Jin Tan Ruan, or its affiliates. All rights reserved.
      </div>
    </div>
  );
}
